import React from "react";
import artwizLogo from "../../images/caseimages/logo-fz.png";
import playAssets from "../../images/caseimages/playAssets.png";
import Intro from "../../images/caseimages/intro-fz.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";
import VsIcon from "../../images/caseimages/vs-logo.png";

import AboutUs from "../../images/caseimages/about-us.png";
import Gallery from "../../images/caseimages/gallery.png";
import HomeFunzone from "../../images/caseimages/home-funzone.png";
import Packages from "../../images/caseimages/packages.png";
import Contact from "../../images/caseimages/contact.png";

function Playandbeyond() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            Crafting Joyful Experiences: Enhancing Play&Beyond Fun Zone's
            Digital Presence
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-32">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">
            What “Play&Beyond Fun Zone” is actually about
          </h2>
          <ul>
            <li className="mb-4">
              Play&Beyond Fun Zone, an indoor playground and event venue in Los
              Angeles, is dedicated to creating an atmosphere filled with love
              and laughter.
            </li>
            <li>
              This project centers on the complete redesign of their website,
              with a focus on delivering an outstanding user experience,
              intuitive navigation, and a joyful, colorful user interface and
              graphic design.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma </span>
              </div>
              <div className="flex items-center mb-4">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio- CSS/HTML</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher </li>
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">Goals and Objectives</h1>
        <h3 className="text-xl pt-8 font-bold">The website redesign aims to</h3>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Improving User Engagement</h3>
            <p>
              Create an interactive and visually attractive platform encouraging
              users to explore the indoor playground and its services.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Increasing Online Bookings</h3>
            <p>
              Streamline the booking process for parties and visits, clear
              pricing information making it user-friendly and convenient.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">
              Providing Essential Information
            </h3>
            <p>
              Ensure that all necessary details, such as party packages,
              feedback, safety measures, and visitor guidelines, are easily
              accessible
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Responsive Design</h3>
            <p>
              Develop a website that adapts seamlessly to various devices,
              including mobile phones and tablets.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">User-Centered Approach</h1>
        <h3 className="text-xl pt-8 font-bold">User Personas</h3>
        <p>
          Two key user personas have been identified for the website redesign:
        </p>
      </div>
      <div className="mb-32">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">
              Role: Parents and Caregivers
            </h3>
            <p>
              Seeking information on the indoor playground's facilities, safety,
              services and its prices.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Role: Party Planners</h3>
            <p>
              Interested in hosting events, such as birthday parties and special
              gatherings.
            </p>
          </div>
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl mb-8">
        UI Design Research
      </h1>
      <ul className="list-disc ml-6">
        <li>
          <b>Inspiration:</b> Drawing ideas and creativity from a range of
          sources, including similar projects and inspiring design elements.
        </li>
        <br />
        <li>
          <b>Style:</b> Defining the visual style and character that would best
          represent the indoor playground's atmosphere. This included the use of
          brand colors and the selection of playful typography.
        </li>
        <br />
        <li>
          <b>Trends:</b> Staying attuned to contemporary design trends to ensure
          the website's look and feel aligns with what's popular in the online
          world.
        </li>
      </ul>
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">Assets</h1>
      <img src={playAssets} alt="UiComponents" className=" mx-auto block" />
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">UI Design</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-28">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Home</h3>
          <p className="text-center pb-4">
            The homepage welcomes visitors with an engaging visual and clear
            pricing information. It provides easy access to booking parties,
            purchasing tickets, and completing electronic waivers. Users can
            explore party packages, discover services, and learn about
            Play&Beyond Fun Zone through a “About Us” section.
          </p>
          <img
            src={HomeFunzone}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          <h3 className="font-bold text-lg mb-2 text-center">Packages</h3>
          <p className="text-center pb-4">
            The "Packages" page presents a detailed comparison of party packages
            and services. Visitors can easily compare these options
          </p>
          <img
            src={Packages}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>

        <div>
          <h3 className="font-bold text-lg mb-2 text-center">About Us</h3>
          <p className="text-center pb-4">
            "About Us” page shares the compelling story, values, and mission
            that drive the indoor playground.
          </p>
          <img
            src={AboutUs}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Gallery</h3>
          <p className="text-center pb-4">
            The “Gallery” is a collection of images that capture the vibrant and
            joyful atmosphere of Play&Beyond Fun Zone.
          </p>
          <img
            src={Gallery}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Contact</h3>
          <p className="text-center pb-4">
            The "Contacts" page provides a contact form for inquiries, a Google
            map displaying the location, and a button that links to the address
            for easy navigation.
          </p>
          <img
            src={Contact}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light "
          />
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl pb-8">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        The website redesign for Play&Beyond Fun Zone focuses on creating an
        engaging, user-friendly experience. It combines attractive design,
        intuitive navigation, comprehensive information, and affordable pricing
        to make party planning and visits easy and enjoyable. The "About Us,"
        “Gallery”, and "Contacts" pages enhance understanding, engagement, and
        communication.
      </p>
    </div>
  );
}

export default Playandbeyond;
