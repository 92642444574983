import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

function MainPage() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define a function to check if a route is active
  const isRouteActive = (route) => {
    return location.pathname === route
      ? "text-red-600 font-bold text-blue"
      : "text-blue-500";
  };

  return (
    <div className="min-h-screen">
      <div
        className={` w-full fixed top-0 z-10 transition-shadow duration-300 ${
          scrolled ? "shadow-md bg-gray-lights" : ""
        }`}
      >
        <div className="flex justify-center py-6  ">
          <ul className="flex">
            <li className="mr-8">
              <Link
                to="/"
                className={` hover:text-blue uppercase transition-colors duration-300 ${isRouteActive(
                  "/"
                )}`}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="about"
                className={`hover:text-blue uppercase transition-colors duration-300 ${isRouteActive(
                  "/about"
                )}`}
              >
                About Me
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="pt-20">
        <Outlet />
      </div>
    </div>
  );
}

export default MainPage;
