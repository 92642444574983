import React from "react";
import artwizLogo from "../../images/caseimages/connectto.png";
import Intro from "../../images/caseimages/connectto_intro.png";

import TicketDetails from "../../images/caseimages/TicketDetails.png";
import Tickets from "../../images/caseimages/Tickets.png";
import Profile from "../../images/caseimages/Profile.png";
import Dashboard from "../../images/caseimages/Dashboard.png";

import ArrowIcon from "../../images/caseimages/arrow.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";
import VsIcon from "../../images/caseimages/vs-logo.png";
import MaterialIcon from "../../images/caseimages/material-logo.png";

function Connectto() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            ConnectTo Customer Relationship Management Platform
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">
            What ConnectTo is actually about
          </h2>
          <ul>
            <li className="mb-4">
              ConnectTo CRM is a comprehensive Customer Relationship Management
              platform designed to streamline customer management, sales
              tracking, and support operations.
            </li>
            <li>
              The app features a user-friendly interface with tools for managing
              tickets, tracking commissions, monitoring user activity, and
              generating reports.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma </span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio(HTML/CSS)</span>
              </div>
              <div className="flex items-center">
                <img src={MaterialIcon} alt="Ai" className="w-8 mr-2" />
                <span>Material UI</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">Enhanced Functionality</h1>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Ticket Management System:</h3>
            <p>
              Users can efficiently organize and track support tickets
              categorized by status (New, In Progress, Done), with detailed
              progress tracking and priority indicators.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">User Profile & Reports:</h3>
            <p>
              Comprehensive user profiles showcase roles, contact details, and
              payroll summaries, enabling easy tracking of commissions, hours
              worked, and salary types.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">
              Real-Time Updates & Notifications:
            </h3>
            <p>
              Instant notifications keep users updated on ticket progress, task
              reminders, and important user actions, improving workflow
              management.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">
              Interactive Users Dashboard:
            </h3>
            <p>
              Displays work history, clock hours, and salary types, along with
              easy-to-use filtering options for date-based report retrieval.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center pb-16 ">
        <h1 className=" uppercase text-2xl  pb-8">User-Centered Approach</h1>
        <p>
          ConnectTo CRM is designed for Customer Support Agents to manage and
          track tickets easily, Sales Managers to monitor sales, commissions,
          and reports, and Administrators to oversee operations and ensure
          smooth workflows with reporting tools and notifications.
        </p>
      </div>

      <h1 className=" uppercase text-center text-2xl mb-8">
        Problems and Solutions
      </h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Customer Support Agents struggled to track ticket statuses and
              manage large volumes of tickets efficiently.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Implemented a clear ticket categorization system (New, In Progress,
            Done) with detailed progress tracking and priority levels to
            streamline ticket management.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Sales Managers needed a better way to monitor commissions and
              generate accurate sales reports.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Created user-friendly dashboards and reporting tools that allow
            Sales Managers to easily track performance, commissions, and sales
            metrics.
          </p>
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl pb-8 mt-16">
        Hi-Fi Mockups
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Dashboard</h3>
          <p className="text-center pb-4">
            The Dashboard displays a comprehensive overview of daily tasks, user
            dashboards, and ticket statuses.
          </p>
          <img
            src={Dashboard}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Tickets</h3>
          <p className="text-center pb-4">
            Categorized tickets (New, In Progress, Done) with clear progress
            indicators for tracking the status of each task.
          </p>
          <img
            src={Tickets}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Profile</h3>
          <p className="text-center pb-4">
            Clean layout presenting the user’s personal information with quick
            access to reports and the option to edit the profile.
          </p>
          <img
            src={Profile}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Ticket Details</h3>
          <p className="text-center pb-4">
            The Ticket Details screen offers comprehensive information for each
            support ticket, including attached files, comments, a detailed
            description and history.
          </p>
          <img
            src={TicketDetails}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
      </div>
    </div>
  );
}

export default Connectto;
