import React from "react";
import artwizLogo from "../../images/caseimages/micma-logo.png";
import Intro from "../../images/caseimages/intro-micma.png";
import MicmaDashboard from "../../images/caseimages/micma-dashboard.jpg";
import Micma1 from "../../images/caseimages/micma-1.jpg";
import Micma2 from "../../images/caseimages/micma-2.jpg";
import Micma3 from "../../images/caseimages/micma-3.jpg";
import Micma4 from "../../images/caseimages/micma-4.png";
import VsIcon from "../../images/caseimages/vs-logo.png";
import jgueryIcon from "../../images/caseimages/jquery.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import PhotoIcon from "../../images/caseimages/adobe-xd.png";

import ArrowIcon from "../../images/caseimages/arrow.png";

function Micma() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className=" p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            Margasoft Integrated Corporate Management Application
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 ">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">
            What “MICMA” is actually about
          </h2>
          <p>
            MICMA is a highly configurable enterprise application designed to
            enhance company efficiency. It streamlines and manages large volumes
            of classified and interconnected data, serving as the primary
            storage for all system objects. Comprising flexible modules, MICMA
            collaborates to construct an accurate software model of
            organizational processes. Built according to industry-standard
            guidelines and requirements, MICMA enables configuration and
            utilization of functions tailored to specific business processes.
          </p>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={PhotoIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe XD</span>
              </div>
              <div className="flex items-center  mb-4">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio </span>
              </div>
              <div className="flex items-center">
                <img src={jgueryIcon} alt="Ai" className="w-8 mr-2" />
                <span>jQuery</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div className="text-center pb-8 ">
        <h3 className="text-xl pt-8 font-bold">
          Most Popular Features of MICMA Platform
        </h3>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Screen Customization</h3>
            <p>
              The consistent yet highly configurable user interface allows
              building screens that speak your language.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Filter & Search</h3>
            <p>
              Search within custom filters or filter your custom search – do it
              in any order you like, and MICMA will always deliver quick and
              consistent results.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Powerful Grids</h3>
            <p>
              Customizable column layouts, in-place edits and batch actions make
              managing lists a breeze in MICMA.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Productivity Tools</h3>
            <p>
              Industry standard planning and tracking tools such as
              appointments, tasks, time tracking and notes boost your
              productivity.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Email Integration</h3>
            <p>
              Access yours as well as all the shared email groups' threads in
              one single location. Create new objects from emails and track the
              thread communications directly from the object.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Robust Security</h3>
            <p>
              MICMA's state of the art authentication and authorization engine
              allows your employees to perform their duties effortlessly without
              having to compromise data security and safety.
            </p>
          </div>
        </div>
      </div>
      <h1 className=" uppercase text-center text-2xl mb-8">
        Problems and Solutions
      </h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Manual and disjointed processes for data management and workflow.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Streamlined Workflow:MICMA provides a centralized platform for
            managing data and workflows, streamlining processes, and reducing
            manual effort.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Inefficient communication and collaboration among team members.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Centralized Data Storage:MICMA acts as the main storage for all
            system objects, ensuring easy access to critical business data and
            improving data integrity.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Lack of centralized storage for critical business data.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Centralized Data Storage: MICMA acts as the main storage for all
            system objects, ensuring easy access to critical business data and
            improving data integrity.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Difficulty in adapting to changing business requirements and
              processes.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Flexibility and Adaptability: MICMA's highly configurable modules
            allow organizations to adapt the platform to their unique business
            requirements, enabling them to easily adjust for changes and
            updates.
          </p>
        </li>
      </ul>
      <h1 className=" uppercase text-center text-2xl mt-16 mb-8">
        My Design Process
      </h1>
      <ul className="list-disc ml-6">
        <li className="pb-4">
          My design process began with understanding business objectives and
          collaborating with the team, stakeholders, developers, market, and
          user researchers, utilizing their findings. I integrated these
          insights and worked on information architecture and user flows. Given
          the specifics of this project and the tight deadline, it was crucial
          to first define the most suitable workflow method for the design
          process. The software development team had adopted agile methodology
          for their process, so I decided to align with them.
        </li>
        <li className="pb-4">
          To streamline the entire development process, I suggested to my team
          that we define the UI patterns and controls we were going to use, and
          as a first step, create our own controls library. This approach saved
          a lot of time and helped us avoid inconsistencies in the design. It
          provided our front-end team with the opportunity to begin their
          processes without waiting for the completion of design implementation
          and user testing.
        </li>
        <li className="pb-4">
          The entire process involved continuous implementation, research, and
          user testing.
        </li>
      </ul>
      <div className="text-center pb-8 mt-16">
        <h1 className=" uppercase text-2xl">User-Centered Approach</h1>
        <h3 className="text-xl pt-8 font-bold">User Personas</h3>
        <p>
          These user groups collectively form the primary user base of MICMA,
          each with specific roles and responsibilities within the system.
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="border border-gray-light rounded-md p-4 ">
          <h3 class="pb-2 text-base font-bold">Role: Administrators</h3>
          <p>
            Administrators are responsible for configuring and managing the
            system, including user accounts, access permissions, and system
            settings.
          </p>
        </div>
        <div className="border border-gray-light rounded-md p-4 ">
          <h3 class="text-base pb-2 font-bold">Role: Managers</h3>
          <p>
            Managers utilize MICMA to monitor business processes, track
            performance metrics, and make strategic decisions.
          </p>
        </div>
        <div className="border border-gray-light rounded-md p-4 ">
          <h3 class="text-base pb-2 font-bold">Role: End users</h3>
          <p>
            End users interact with MICMA daily to perform their job functions,
            such as entering data, accessing information, and completing tasks
            within the system.
          </p>
        </div>
      </div>
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">UI Design</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">
            Micma Dashboard
          </h3>
          <img
            src={MicmaDashboard}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Task</h3>
          <img
            src={Micma3}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Create A Task</h3>
          <img
            src={Micma4}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Documents</h3>
          <img
            src={Micma1}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Configuration</h3>
          <img
            src={Micma2}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light "
          />
        </div>
      </div>

      <h1 className="uppercase text-center text-2xl pb-8">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        In conclusion, the goal of our project was to develop an efficient
        enterprise application that streamlined data management and enhanced
        productivity. Through a collaborative design process, we synthesized
        insights from stakeholders and research to align with project
        objectives. By adopting agile methodology, we navigated tight deadlines
        with flexibility and efficiency. The creation of a controls library
        facilitated swift front-end implementation, while continuous iteration
        and user testing ensured our solution met user needs effectively.
        Overall, our process enabled us to deliver a robust application that met
        business objectives and enhanced organizational efficiency.
      </p>
    </div>
  );
}

export default Micma;
