import React from "react";
import siteMap from "../../images/caseimages/SiteMap.png";
import artwizLogo from "../../images/caseimages/logo.png";
import Intro from "../../images/caseimages/intro.png";
import UiComponents from "../../images/caseimages/artwizui.png";
import OnboardingWelcome from "../../images/caseimages/OnboardingWelcome.png";
import Welcome1 from "../../images/caseimages/Welcome1.png";
import Welcome2 from "../../images/caseimages/Welcome2.png";
import AvailableCategories from "../../images/caseimages/AvailableCategories.png";
import Easy from "../../images/caseimages/Easy.png";
import Quiz2 from "../../images/caseimages/Quiz2.png";

import Correct from "../../images/caseimages/Correct.png";
import Award from "../../images/caseimages/award.png";
import Quiz1 from "../../images/caseimages/Quiz1.png";

import Wrong from "../../images/caseimages/wrong.png";
import Failed from "../../images/caseimages/Failed.png";
import ModerateLevel from "../../images/caseimages/ModerateLevel.png";

import Hint from "../../images/caseimages/hint.png";
import StrongLevel from "../../images/caseimages/StrongLevel.png";
import Help from "../../images/caseimages/Help.png";

import Award1 from "../../images/caseimages/Award1.png";
import Award2 from "../../images/caseimages/Award2.png";
import Award3 from "../../images/caseimages/Award3.png";

import ArrowIcon from "../../images/caseimages/arrow.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";

function Artwiz() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className=" p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            ArtWiz is an interactive and educational quiz app designed as the
            Minimum Viable Product (MVP) for users aged 12 and up. It offers an
            engaging platform to test and enrich one's graphic design knowledge.
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-32">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">Problem Statement</h2>
          <ul className="list-disc ml-6">
            <li>
              The need to create an engaging and educational quiz app for users
              aged 12 and up.
            </li>
            <li>
              The requirement to offer a seamless user experience to effectively
              educate and challenge users.
            </li>

            <li>
              The goal to provide an interactive platform for users to test
              their graphic design knowledge and learn.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma</span>
              </div>
              <div className="flex items-center">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h1 className=" uppercase text-center text-2xl">
        User-Centered Approach
      </h1>
      <h3 className=" text-center text-xl pb-8 pt-8 font-bold">
        User Personas
      </h3>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="p-4">
          <p className="font-bold text-lg">Persona 1: Creative Nina</p>
          <p>15, High school student</p>
          <div className=" pt-4">
            <p>
              <b>Background:</b> Nina is a creative teenager with a passion for
              art and graphic design. She enjoys experimenting with digital art
              and has a desire to learn more about graphic design principles to
              improve her skills.
            </p>
            <br />
            <p>
              <b>Goals:</b> Nina wants to use the app to test her knowledge of
              graphic design concepts and gain a deeper understanding of color
              theory, typography, and design principles.
            </p>
          </div>
        </li>
        <li className="p-4">
          <p className="font-bold text-lg">Persona 2: Lifelong Learner Lisa</p>
          <p>40, Parent and part-time worker</p>
          <div className=" pt-4">
            <p>
              <b>Background:</b> Lisa is a busy parent and part-time worker who
              has always been intrigued by graphic design. She's now looking for
              opportunities to learn and explore her creative side.
            </p>
            <br />
            <p>
              <b>Goals:</b> Lisa wants to use the app to explore graphic design
              as a hobby and gain a deeper appreciation for design principles.
            </p>
          </div>
        </li>
      </ul>
      <h3 className=" text-center text-xl pb-8 pt-8 font-bold">
        Competitor Analysis
      </h3>
      <p className=" text-center">
        Researched existing quiz apps and educational platforms to identify
        design trends and user expectations. <br />
        Benchmarked against competitors to create a unique and effective
        learning experience.
      </p>

      <h1 className=" uppercase text-center text-2xl mt-32 mb-8">
        Problems and Solutions
      </h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problems</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Designing an app that appeals to users aged 12 and up with varying
              levels of design knowledge.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto " />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Created an interactive onboarding process that accommodates
            different age groups by offering both easy and advanced difficulty
            levels. This allows users to self-select their comfort level.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problems</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Making the app accessible to users with different learning
              preferences and accessibility needs.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Incorporated options for text-to-speech, customizable font sizes,
            and color themes to ensure a user-friendly experience for all users.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problems</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Striking a balance between an engaging visual design and
              educational content.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Designed the app with a visually appealing interface that includes
            badges and achievements to motivate users. Ensured that educational
            content, hints, and explanations are readily available for learning.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problems</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Creating a system to track user achievements and progress.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Implemented a "Achievement" page to celebrate user advancement,
            displaying levels represented by colors: green, blue, and red.
            Additionally, an "Awarded" section helps users track their
            achievements and badges.
          </p>
        </li>
      </ul>
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">Sitemap</h1>
      <img src={siteMap} alt="SiteMap" />
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">
        UI Components
      </h1>
      <img src={UiComponents} alt="UiComponents" className="mx-auto block" />
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">UI Design</h1>
      <h3 className="font-bold text-lg mb-2 text-center">Onboarding</h3>
      <p className="text-center">
        The app's main landing screen, providing access to key sections and
        navigation.
      </p>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
        <li className="bg-white p-8 text-center">
          <img
            src={OnboardingWelcome}
            alt="Intro"
            className="max-w-full h-auto"
          />
        </li>
        <li className="bg-white p-8 text-center">
          <img src={Welcome1} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <img src={Welcome2} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Level & Categories</h3>
          <p>
            This screen allows for the selection of different difficulty levels
            and quiz categories.
          </p>
          <img
            src={AvailableCategories}
            alt="Intro"
            className="max-w-full h-auto"
          />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Start Quiz</h3>
          <p>
            A quiz challenge can be initiated by selecting a category and
            difficulty level on this screen.
          </p>
          <img src={Easy} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Quiz 1</h3>
          <p>
            This level is associated with the color green, symbolizing a
            beginner-friendly and easy quiz.
          </p>
          <img src={Quiz2} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>

      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Correct Answer</h3>
          <p>
            Confirmation is provided on this screen that your answer to a quiz
            question is correct.
          </p>
          <img src={Correct} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Awarded</h3>
          <p>
            On this screen, the achievement of advancing to a new level in the
            app is celebrated.
          </p>
          <img src={Award} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Quiz 2</h3>
          <p>
            On this quiz, the topic is "fonts," allowing users to test their
            knowledge in typeface.
          </p>
          <img src={Quiz1} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>

      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Wrong Answer</h3>
          <p>
            Confirmation is given on this screen that your answer to a quiz
            question is incorrect.
          </p>
          <img src={Wrong} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Failed</h3>
          <p>
            On this screen, the achievement of advancing to a new level in the
            app is failed.
          </p>
          <img src={Failed} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Moderate Level</h3>
          <p>
            The color blue is used to represent this level, offering a balanced
            and moderately challenging quiz experience.
          </p>
          <img src={ModerateLevel} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>

      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Hint</h3>
          <p>
            Clues and assistance for tricky quiz questions can be found by
            clicking hint button .
          </p>
          <img src={Hint} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">Strong Level</h3>
          <p>
            This level is represented by the color red, indicating a highly
            challenging and advanced quiz experience.
          </p>
          <img src={StrongLevel} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <h3 className="font-bold text-lg mb-2">F.A.Q.</h3>
          <p>
            On this screen, commonly asked questions are addressed and answered,
            providing users with information and solutions to common queries.
          </p>
          <img src={Help} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>
      <h3 className="font-bold text-lg mb-2 text-center">Achievement</h3>
      <p className="text-center">
        On this screens display the levels of achievement, which are represented
        by the colors green, blue, and red.
      </p>
      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 ">
        <li className="bg-white p-8 text-center">
          <img src={Award1} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <img src={Award2} alt="Intro" className="max-w-full h-auto" />
        </li>
        <li className="bg-white p-8 text-center">
          <img src={Award3} alt="Intro" className="max-w-full h-auto" />
        </li>
      </ul>
      <a
        className=" text-blue underline text-center uppercase "
        href="https://www.figma.com/proto/zaeCXjK9BxLXugOohpYsJn/ArtWiz?page-id=3%3A15&type=design&node-id=60-374&viewport=192%2C317%2C0.09&t=KA9F4sfAv0IR0ppF-1&scaling=scale-down&starting-point-node-id=60%3A374&mode=design"
        target="_blank"
        rel="noopener noreferrer"
      >
        Figma Prototyping
      </a>
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        ArtWiz is designed to provide an engaging and educational experience for
        users aged 12 and up, allowing them to test their graphic design
        knowledge, learn, and progress with the help of hints and explanations.
      </p>
    </div>
  );
}

export default Artwiz;
