import React from "react";
import FffLogo from "../../images/caseimages/fitfoodfinder-logo.png";
import Intro from "../../images/caseimages/intro-fitfoodfinder.png";
import UiComponents from "../../images/caseimages/asstets.png";
import SiteMap from "../../images/caseimages/sitemap-fff.png";
// import UserFlows from "../../images/caseimages/userflows-fff.png";

import ProfileW from "../../images/caseimages/Profile-w.png";
import HomeWiframes from "../../images/caseimages/HOME-w.png";
import Registration from "../../images/caseimages/REGISTRAATION-w.png";
import PhotoUpload from "../../images/caseimages/PhotoUpload.png";
import DietTypes from "../../images/caseimages/DietTypes.png";
import CreatedProfile from "../../images/caseimages/CreatedProfile.png";
import Login from "../../images/caseimages/Login-fff.png";

import CreateRecipe from "../../images/caseimages/Create Recipes-w.png";
import Recipes from "../../images/caseimages/RecipeLibrary-w.png";
import HOMEfff from "../../images/caseimages/HOME-fff.png";
import REGISTRAATION from "../../images/caseimages/REGISTRAATION.png";
import RecipeLibrary from "../../images/caseimages/RecipeLibrary.png";
import PhotoUpload1 from "../../images/caseimages/PhotoUpload-1.png";
import CreateRecipes from "../../images/caseimages/CreateRecipes.png";
import RecipeLibraryDetails from "../../images/caseimages/RecipeLibraryDetails.png";
import ExploreSharedPlans from "../../images/caseimages/ExploreSharedPlans.png";
import CreatingWeeklyPlan from "../../images/caseimages/CreatingWeeklyPlan.png";
import CreateMealPlan from "../../images/caseimages/CreateMealPlan.png";
import Allergies from "../../images/caseimages/Allergies.png";

import ArrowIcon from "../../images/caseimages/arrow.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";

function Fitfoodfinder() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4 flex items-center justify-center flex-col">
          <img src={FffLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            FitFoodFinder - A Comprehensive Meal Planning and Nutrition Platform
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-32">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">
            What “FitFoodFinder” is actually about
          </h2>
          <ul>
            <li className="mb-4">
              FitFoodFinder is a groundbreaking project that was conceptualized
              to address the growing need for a user-friendly and holistic meal
              planning and nutrition platform. Created and moderated by a
              licensed nutritionist, this platform is designed to empower users
              to make informed dietary choices, plan meals, and engage with a
              supportive community of like-minded individuals.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma </span>
              </div>
              <div className="flex items-center mb-4">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">Comprehensive Features</h1>
        <h3 className="text-xl pt-8 font-bold">
          FitFoodFinder offers a comprehensive solution by combining the
          following key elements
        </h3>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Personalized Meal Planning</h3>
            <p>
              Users can create daily or weekly meal plans tailored to their
              dietary preferences, health goals, and family needs. The platform
              suggests recipes and automatically generates shopping lists.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Diverse Recipe Library </h3>
            <p>
              FitFoodFinder offers an extensive Recipe Library with recipes
              customized to different dietary preferences, providing a wide
              variety of options for all users.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Community Engagement</h3>
            <p>
              Users can explore shared meal plans created by others, leave
              comments, and provide feedback. They can also participate in
              discussions, access nutrition articles, how-to guides, and watch
              videos.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Educational Resources</h3>
            <p>
              FitFoodFinder provides educational resources to enhance users'
              nutritional knowledge, making it easier to make informed dietary
              choices.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">User-Centered Approach</h1>
        <h3 className="text-xl pt-8 font-bold">User Personas</h3>
      </div>

      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-32">
        <li className="p-4">
          <p className="font-bold text-lg">Persona 1: Luiza A.</p>
          <p>46, Los Angeles, CA.</p>
          <p className=" pt-4">
            <b>Goals:</b> Maintain a healthy lifestyle, find quick nutritious
            recipes, and manage daily calorie intake for weight loss.
          </p>
          <br />
          <p>
            <b>Challenges:</b> Limited time for meal planning and aligning meals
            with her low-carb preferences.
          </p>
          <br />
          <p>
            <b>FitFoodFinder Support:</b> Personalized low-carb meal planning,
            quick and healthy recipes, calorie tracking.
          </p>
        </li>
        <li className="p-4">
          <p className="font-bold text-lg">Persona 2: Irina S.</p>
          <p>39, Pasadena, CA.</p>
          <p className=" pt-4">
            <b>Goals:</b> Provide nutritious family meals, accommodate dietary
            restrictions vegetarian daughter, gluten-intolerant husband, and
            balance work and family time.
          </p>
          <br />
          <p>
            <b>Challenges:</b> Meeting diverse dietary needs and limited time
            for meal planning.
          </p>
          <br />
          <p>
            <b>FitFoodFinder Support:</b> Diverse recipes, family-friendly meal
            plans, automated shopping lists, time-saving meal planning.
          </p>
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl mb-8">
        Competitor Analysis
      </h1>
      <ul className="list-disc ml-6">
        <li className="pb-4">
          In research, it was clear that similar platforms often use cooking
          videos along with written recipes, meeting users' preferences for
          engaging video content. To adapt to these preferences, FitFoodFinder
          has integrated videos.
        </li>
        <li>
          Another research revealed that many comparable platforms underuse the
          BMI calculator, which can be valuable for tracking health and fitness
          progress. This suggests an opportunity for FitFoodFinder to offer a
          unique tool for users.
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl mb-8 mt-32">
        User Testing
      </h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Although FitFoodFinder has a community section, testing shows that
              users are not actively participating in discussions or sharing
              their meal plans and recipes. This results in an underutilized
              community feature.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            FitFoodFinder introduces "Community Challenges" that encourage users
            to share their meal plans, recipes, and engage in discussions. Users
            who actively participate in these challenges have the opportunity to
            win rewards and gain recognition within the community.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Users are expressing concerns about the difficulty of sourcing
              certain ingredients for featured recipes. Some ingredients are
              exotic or not readily available in their local stores, causing
              inconvenience and frustration.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            FitFoodFinder integrates a "Ingredient Substitution" feature that
            suggests alternative ingredients that are more accessible to users
            based on their location.
          </p>
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">
        Information Architecture
      </h1>
      <a
        className=" text-blue underline text-center uppercase mb-16"
        href="https://www.figma.com/proto/GAWc0YXHiwFLDvormotQi5/FitFoodFinder?page-id=3%3A239&type=design&node-id=1048-12809&viewport=25%2C555%2C0.18&t=h8rE6QZWt9FmyLmC-1&scaling=min-zoom&mode=design"
        target="_blank"
        rel="noopener noreferrer"
      >
        Figma IA Presentation
      </a>
      <img src={SiteMap} alt="UiComponents" className=" mx-auto block" />
      {/* 
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">User Flows</h1>
      <img src={UserFlows} alt="UiComponents" className="mx-auto block" /> */}

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">Wireframes</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-28">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Home</h3>
          <img src={HomeWiframes} alt="Intro" className="max-w-full h-auto" />
          <h3 className="font-bold text-lg mb-2 text-center mt-8">Recipes</h3>
          <img src={Recipes} alt="Intro" className="max-w-full h-auto" />
        </div>
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Registration</h3>
          <img src={Registration} alt="Intro" className="max-w-full h-auto" />

          <h3 className="font-bold text-lg mb-2 text-center mt-8">
            Registration
          </h3>
          <img src={ProfileW} alt="Intro" className="max-w-full h-auto" />

          <h3 className="font-bold text-lg mb-2 text-center mt-8">
            Create Recipe
          </h3>
          <img src={CreateRecipe} alt="Intro" className="max-w-full h-auto" />
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl pb-8">UI Components</h1>
      <img src={UiComponents} alt="UiComponents" className="mx-auto block" />

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">UI Design</h1>
      <a
        className=" text-blue underline text-center uppercase mb-16"
        href="https://www.figma.com/proto/GAWc0YXHiwFLDvormotQi5/FitFoodFinder?page-id=279%3A481&type=design&node-id=279-482&viewport=287%2C686%2C0.04&t=wSxKd63ygS1aSoR0-1&scaling=scale-down-width&starting-point-node-id=839%3A3665&mode=design"
        target="_blank"
        rel="noopener noreferrer"
      >
        Figma Prototyping
      </a>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-28">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Home</h3>
          <p className="text-center pb-4">
            The Home Page is the gateway to the FitFoodFinder platform This page
            is designed to provide a welcoming and informative introduction to
            the platform's offerings.
          </p>
          <img
            src={HOMEfff}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Recipes</h3>
          <p className="text-center pb-4">
            The Recipe Section is the heart of FitFoodFinder, where users
            explore a diverse collection of nutritious recipes. Users can browse
            recipes based on specific diets, ingredients, cooking time, and
            more. The Recipe Section empowers users to find, plan, and enjoy
            wholesome meals that align with their health and dietary goals.
          </p>
          <img
            src={RecipeLibrary}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Recipe Details</h3>
          <p className="text-center pb-4">
            The Recipe Details page is all about providing users with
            comprehensive information about a specific recipe, including its
            preparation steps, cooking time, nutritional details, dietary
            information, and user reviews.
          </p>
          <img
            src={RecipeLibraryDetails}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Meal Plan</h3>
          <p className="text-center pb-4">
            Meal Plan page is where user can build a personalized meal plan for
            the week ahead.
          </p>
          <img
            src={CreatingWeeklyPlan}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Create Plan</h3>
          <p className="text-center pb-4">
            The Create Plan Page is where users embark on their personalized
            journey to plan nutritious and balanced meal schedules.
          </p>
          <img
            src={CreateMealPlan}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>

        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Registration</h3>
          <p className="text-center pb-4">
            During registration, users typically provide details like their
            name, email, and password. Additionally, this page may allow users
            to customize their profiles by specifying dietary preferences and
            other relevant information.
          </p>
          <img
            src={REGISTRAATION}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <img
            src={PhotoUpload}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <img
            src={DietTypes}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <img
            src={Allergies}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <img
            src={CreatedProfile}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Profile</h3>
          <p className="text-center pb-4">
            The profile is a reflection of the unique dietary preferences,
            goals, and progress. It's the space to tailor the FitFoodFinder
            experience, ensuring that the platform aligns with all individual
            needs.
          </p>
          <img
            src={Login}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <img
            src={PhotoUpload1}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Create Recipe</h3>
          <p className="text-center pb-4">
            The Create Recipe Page is where culinary enthusiasts and
            nutrition-conscious individuals can contribute their own unique
            recipes to the FitFoodFinder community.
          </p>
          <img
            src={CreateRecipes}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Community</h3>
          <p className="text-center pb-4">
            The Community Page is the heart of FitFoodFinder's interactive
            platform, where users come together to discuss, share, and support
            each other in their pursuit of healthier eating habits.
          </p>
          <img
            src={ExploreSharedPlans}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl pb-8">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        "FitFoodFinder, co-founded and guided by a nutritionist dietitian, goes
        beyond being a meal planning app; it's a comprehensive nutrition
        platform that empowers users to lead healthier lives. With the expert
        guidance of a nutritionist dietitian, users receive the support and
        tools they need to make positive changes in their daily and weekly
        dietary choices, helping them not only achieve their weight loss goals
        but also enhance their overall well-being through healthy eating. By
        fostering a sense of community, providing personalized nutrition
        insights, and offering a diverse array of recipes, FitFoodFinder, under
        the guidance a nutritionist, is poised to revolutionize how individuals
        approach their dietary choices and nutritional goals."
      </p>
    </div>
  );
}

export default Fitfoodfinder;
