import React from "react";
import Intro from "../images/me.jpg";

function About() {
  return (
    <div className="flex flex-col justify-center text-center px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32 ">
      <div class="flex flex-col md:flex-row  mt-16">
        <div class="md:w-1/2 text-center md:text-left md:pr-16">
          <h1 class="text-1xl md:text-2xl lg:text-3xl text-gray leading-10">
            About Me
          </h1>

          <div class="text-lg mt-10">
            <p className="pb-4">
              Hello! I'm Lily, a UX/UI Designer from Pasadena, CA, with a focus
              on Visual and Interaction Design. With over a decade of
              experience, I specialize in crafting sleek web and mobile
              applications with engaging interfaces. From thorough user research
              to creating prototypes, I bring designs to life using HTML/CSS and
              React.js. My goal is to seamlessly blend creativity and
              functionality to tell captivating stories through each project.
            </p>
          </div>

          <h1 class="text-xl md:text-1xl lg:text-2xl text-gray leading-10 mt-8">
            Interests
          </h1>

          <div class="text-lg mt-2">
            <p className="pb-4">
              I revel in the exploration of interior design and take pleasure in
              crafting distinctive handmade decor items. My passion extends to
              travel, where I indulge in gastronomy tourism and the exploration
              of architectural, natural, and artistic wonders around the globe.
            </p>
          </div>
        </div>
        <div class="md:w-1/2">
          <img src={Intro} alt="profile" class="mr-2" />

          <div class="text-lg mt-2">
            <p className="pb-4">
              <p>
                <b>Email:</b> lilia.chobanyan@gmail.com
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
