import React from "react";
import artwizLogo from "../../images/caseimages/phpos-logo.png";
import Intro from "../../images/caseimages/intro-pos.png";
import PosIntro from "../../images/caseimages/pos-intro.jpg";
import Pos1 from "../../images/caseimages/pos1.jpg";
import Pos2 from "../../images/caseimages/pos2.jpg";
import Pos3 from "../../images/caseimages/pos3.jpg";
import Pos4 from "../../images/caseimages/pos4.jpg";
import Pos5 from "../../images/caseimages/pos5.jpg";
import VsIcon from "../../images/caseimages/vs-logo.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import PhotoIcon from "../../images/caseimages/Adobe_Photoshop_CC_icon.png";

function Pos() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className=" p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            A custom POS system for a health store, ensuring efficient inventory
            management, precise barcode scanning, order tracking, and secure
            transactions and incorporating a timesheet review for workers.
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 ">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">Problem Statement</h2>
          <ul className="list-disc ml-6">
            <li>
              The challenge at hand is to streamline and enhance the process of
              tracking workers' timesheets.
            </li>
            <li>
              The POS system should prioritize features such as robust inventory
              management, barcode scanning for quick and accurate transactions,
              order tracking capabilities, and a secure transaction process.
            </li>
            <li>
              The system needs to have a user-friendly interface, ensuring easy
              navigation and minimal training for staff.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={PhotoIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Photoshop</span>
              </div>
              <div className="flex items-center  mb-4">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio, WPF, XAML</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">UI Design</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-28">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Intro</h3>

          <img
            src={PosIntro}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">TimeSheet</h3>

          <img
            src={Pos2}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Calculator</h3>

          <img
            src={Pos4}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>

        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Sale</h3>
          <img
            src={Pos1}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          <h3 className="font-bold text-lg mb-2 text-center">Sale History</h3>
          <img
            src={Pos3}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          <h3 className="font-bold text-lg mb-2 text-center">
            TimeSheet Review
          </h3>
          <img
            src={Pos5}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
      </div>
    </div>
  );
}

export default Pos;
