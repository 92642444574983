import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import MainPage from "./pages/MainPage";
import About from "./pages/About";
import Artwiz from "./pages/casestudies/Artwiz";
import Connectto from "./pages/casestudies/Connectto";
import Playandbeyond from "./pages/casestudies/Playandbeyond";
import Fitfoodfinder from "./pages/casestudies/Fitfoodfinder";
import Trackmylab from "./pages/casestudies/Trackmylab";
import Micma from "./pages/casestudies/Micma";
import Pos from "./pages/casestudies/Pos";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<MainPage />}>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="artwiz" element={<Artwiz />} />
            <Route path="connectto" element={<Connectto />} />
            <Route path="playandbeyond" element={<Playandbeyond />} />
            <Route path="fitfoodfinder" element={<Fitfoodfinder />} />
            <Route path="trackmylab" element={<Trackmylab />} />
            <Route path="micma" element={<Micma />} />
            <Route path="pos" element={<Pos />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
