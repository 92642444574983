import React from "react";
import artwizLogo from "../../images/caseimages/logo-truck.png";
import Intro from "../../images/caseimages/truckIntro.png";
import UiComponents from "../../images/caseimages/Assets.png";
import UiComponents2 from "../../images/caseimages/designElements.png";

import Login from "../../images/caseimages/Login.png";
import Home from "../../images/caseimages/Home-tml.png";

import TestDictionary from "../../images/caseimages/TestDictionary.png";
import ClientInfo from "../../images/caseimages/ClientInfo.png";
import Questionnaire from "../../images/caseimages/Questionnaire.png";
import LabResut from "../../images/caseimages/LabResut.png";

import ArrowIcon from "../../images/caseimages/arrow.png";
import AiIcon from "../../images/caseimages/ai-icon.png";
import FigmaIcon from "../../images/caseimages/logos_figma.png";
import VsIcon from "../../images/caseimages/vs-logo.png";
import ReactIcon from "../../images/caseimages/react-logo.png";
import MaterialIcon from "../../images/caseimages/material-logo.png";

function Trackmylab() {
  return (
    <div className="flex flex-col mt-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4 flex items-center justify-center flex-col">
          <img src={artwizLogo} alt="Logo" style={{ maxWidth: "200px" }} />
          <p className="text-center mt-4  text-xl">
            TrackMyLab- Accurate Health Information, Better Health Decisions
          </p>
        </li>
        <li className=" p-4 ">
          <img src={Intro} alt="Intro" />
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 mb-16">
        <li className="p-4">
          <h2 className="font-bold text-lg mb-2">
            What “TrackMyLab” is actually about
          </h2>
          <ul>
            <li className="mb-4">
              TrackMyLab is a CRM designed for a national nonprofit organization
              dedicated to promoting better health through informed
              decision-making.
            </li>
            <li>
              The TrackMyLab redesign project focused on improving the UI/UX
              design and creating reusable React components. The primary users
              of this TrackMyLab include doctors, nurses, assistants, and data
              entry specialists.
            </li>
          </ul>
        </li>
        <li className="p-4">
          <ul className="flex flex-col md:flex-row">
            <li className="mb-4 md:w-1/2 md:pr-4">
              <h2 className="font-bold text-lg mb-2">Tools Used</h2>
              <div className="flex items-center mb-4">
                <img src={FigmaIcon} alt="Ai" className="w-8 mr-2" />
                <span>Figma </span>
              </div>
              <div className="flex items-center mb-4">
                <img src={AiIcon} alt="Ai" className="w-8 mr-2" />
                <span>Adobe Illustration</span>
              </div>
              <div className="flex items-center mb-4">
                <img src={VsIcon} alt="Ai" className="w-8 mr-2" />
                <span>Visual Studio</span>
              </div>
              <div className="flex items-center mb-4">
                <img src={ReactIcon} alt="Ai" className="w-8 mr-2" />
                <span>React.js</span>
              </div>
              <div className="flex items-center">
                <img src={MaterialIcon} alt="Ai" className="w-8 mr-2" />
                <span>Material UI</span>
              </div>
            </li>
            <li className="md:w-1/2">
              <h2 className="font-bold text-lg mb-2">My Role</h2>
              <ul className="list-disc ml-6">
                <li>UX Researcher</li>
                <li>UX/UI designer</li>
                <li>UI Developer</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">Enhanced Functionality</h1>
        <h3 className="text-xl pt-8 font-bold">
          Enhancing UX and Reusability for TrackMyLab Application
        </h3>
      </div>
      <div className="mb-32 ">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Questionnaire Section</h3>
            <p>
              Enhancing the user experience in the Questionnaire section, making
              it easier to enter and work with data.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Table UI Design</h3>
            <p>
              Improving the user experience within the Questionnaire section to
              simplify data entry and usage.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Client Info Section</h3>
            <p>
              To refine the Client Information section, providing a more
              user-friendly and informative experience. Users will be able to
              access comprehensive client details with ease.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Reusable UI Components</h3>
            <p>
              Providing a comprehensive set of reusable UI components in both
              Figma and React.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center pb-8 ">
        <h1 className=" uppercase text-2xl">User-Centered Approach</h1>
        <h3 className="text-xl pt-8 font-bold">User Personas</h3>
        <p>
          Introducing potential users of the CRM, including doctors, nurses,
          assistants, and data entry specialists.
        </p>
      </div>
      <div className="mb-32">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="pb-2 text-base font-bold">Role: Assistant</h3>
            <p>
              Administrative assistant responsible for managing appointment
              schedules, patient records, and supporting the medical team with
              administrative tasks. She plays a key role in improving the
              questionnaire section of the application.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">
              Role: Data Entry Specialist
            </h3>
            <p>
              Max P. specializes in entering and organizing patient data in the
              TrackMyLab. He ensures that data accuracy is maintained and that
              all records are up to date.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class=" text-base pb-2 font-bold">Role: Doctor</h3>
            <p>
              Dr. Emily M. is a experienced medical professional with a
              specialization in diagnostic medicine. She's responsible for
              reviewing patient data, diagnosing medical conditions, prescribing
              treatment plans, and leaving notes within the TrackMyLab.
            </p>
          </div>
          <div className="border border-gray-light rounded-md p-4 ">
            <h3 class="text-base pb-2 font-bold">Role: Nurse</h3>
            <p>
              Nurse James A. is a healthcare professional responsible for
              patient care and assisting doctors with various medical
              procedures. He often records and updates patient information in
              the TrackMyLab.
            </p>
          </div>
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl mb-8">
        Problems and Solutions
      </h1>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Data entry specialists who are using TrackMyLab face usability
              challenges within the questionnaire section, which leads to errors
              and inefficiencies in collecting patient data.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Improvements in the questionnaire section to include more
            user-friendly forms and robust data validation, reducing errors and
            increasing efficiency.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              The Client Info section in TrackMyLab needs essential billing and
              appointment features, which are currently missing. This makes it
              difficult for healthcare professionals to manage critical aspects
              of patient care.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Enhance the Client Info section by integrating billing and
            appointment features. This integration will streamline operations
            and improve the overall user experience.
          </p>
        </li>
      </ul>
      <ul className="grid gap-4 sm:grid-cols-1 md:grid-cols-2">
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Problem</h3>
          <p className="flex items-center">
            <span className="mr-4">
              Important client info like name, age, and last visit has to be
              consistently shown in all sections. Currently for healthcare
              professionals it is hard to quickly find what they need about
              their patients.
            </span>
            <img src={ArrowIcon} alt="arrow" className="ml-auto w-4 h-auto" />
          </p>
        </li>
        <li className="mb-8">
          <h3 className="font-bold text-lg mb-2">Solution</h3>
          <p>
            Create a standardized display of client information, including name,
            age, and last visit, at the top of every section within the
            application.
          </p>
        </li>
      </ul>

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">Assets</h1>
      <img src={UiComponents} alt="UiComponents" className=" mx-auto block" />
      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">
        UI Elements
      </h1>
      <img src={UiComponents2} alt="UiComponents" className=" mx-auto block" />

      <h1 className=" uppercase text-center text-2xl pb-8 mt-32">
        Hi-Fi Mockups
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-28">
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Login Page</h3>
          <p className="text-center pb-4">
            This section allows authorized users to log into the system. Users
            can also add, edit, view, and leave comments on client records.
          </p>
          <img
            src={Login}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          {/* <h3 className="font-bold text-lg mb-2 text-center">
            pH Clients Page
          </h3>
          <p className="text-center pb-4">
            The 'PH Clients' section is the gateway to managing patient
            information. It conveniently displays two essential lists: 'Newly
            Added Clients' and 'Ongoing Reviewing Clients.' This allows
            healthcare professionals to efficiently keep track of new patient
            entries and easily access recently reviewed records.
          </p>
          <img
            src={Reviewed}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          /> */}

          <h3 className="font-bold text-lg mb-2 text-center">
            Client Info Page
          </h3>
          <p className="text-center pb-4">
            This section is an all-in-one solution for client management. It
            includes client information, billing, appointment features, and a
            doctor's notes section, streamlining client care and enhancing
            record-keeping."
          </p>
          <img
            src={ClientInfo}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
          <h3 className="font-bold text-lg mb-2 text-center">Lab Result</h3>
          <p className="text-center pb-4">
            The Lab Result section provides data visualizations, a tabular lab
            result list, and a notes block for streamlined data access and
            enhanced patient care.
          </p>
          <img
            src={LabResut}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
        <div>
          <h3 className="font-bold text-lg mb-2 text-center">Home Page</h3>
          <p className="text-center pb-4">
            The home page features with user-friendly interface where healthcare
            professionals can add new client information through intuitive
            forms. Additionally, it provides a quick overview of the last 4
            clients, displaying their key details, and offering direct links to
            their private sections for effective management.
          </p>
          <img
            src={Home}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          <h3 className="font-bold text-lg mb-2 text-center">
            Text Dictionary Page
          </h3>
          <p className="text-center pb-4">
            The "Text Dictionary" page provides in-depth descriptions of various
            lab tests, including those related to urine, blood, and more. Each
            entry offers detailed insights into specific tests, their purposes,
            and the associated normal reference ranges.
          </p>
          <img
            src={TestDictionary}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />

          <h3 className="font-bold text-lg mb-2 text-center">
            Health Questionnaire Page
          </h3>
          <p className="text-center pb-4">
            Health Questionnaire Section is a gateway to comprehensive patient
            health insights. It's a user-friendly and informative section where
            patients provide essential details about their health, medical
            history, and lifestyle.
          </p>
          <img
            src={Questionnaire}
            alt="Intro"
            className="max-w-full h-auto border border-gray-light mb-16"
          />
        </div>
      </div>

      <h1 className=" uppercase text-center text-2xl pb-8">Conclusion</h1>
      <p className="bg-white pb-16 text-center">
        TrackMyLab CRM's makeover has improved clients’ healthcare management.
        It's now more user-friendly with consistent client info and new features
        for billing and appointments. The 'Text Dictionary,' 'Health
        Questionnaire,' and 'Lab Test Chart' help healthcare pros make better
        decisions.{" "}
      </p>
    </div>
  );
}

export default Trackmylab;
